import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings, Settings } from './app.settings';
import {
  NgcCookieConsentService,
  NgcInitializationErrorEvent,
  NgcInitializingEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from './shared/_services/localStorage.service';
import { Carousel } from 'primeng/carousel';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from '@shared/_services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  cookieValue: any;

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    public translate: TranslateService,
    private localStorage: LocalStorageService,
    private readonly _registry: MatIconRegistry,
    private readonly _sanitize: DomSanitizer,
    private languageService: LanguageService
  ) {
    this.settings = this.appSettings.settings;
    translate.addLangs(['en', 'de', 'fr', 'ru', 'tr', 'ro']);
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe((event) => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          //localStorage.remove("hotelFilters");
        }
      });
    Carousel.prototype.onTouchMove = () => {};
    // this.cookieService.set('Test', 'Hello World');
    // this.cookieValue = this.cookieService.get('Test');

    // console.log("linkul care imi trebuie mie", router.url.length);
  }

  async importMapLibs() {
    const { Map } = (await google.maps.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;
  }

  ngOnInit() {
    this.registerMaterialIcons();
    this.importMapLibs();
    if (localStorage.getItem('colorMode')) {
      document.documentElement.setAttribute(
        'data-theme',
        localStorage.getItem('colorMode')
      );
    } else {
      localStorage.setItem('colorMode', 'light');
      document.documentElement.setAttribute(
        'data-theme',
        localStorage.getItem('colorMode')
      );
    }

    this.languageService.language$.subscribe((lang) => {
      if (this.translate.getLangs().includes(lang)) {
        this.translate.use(lang);
      } else {
        this.translate.use('ro');
      }
    });
  }

  ngAfterViewInit() {
    //   if (this.router.url.includes('/client')) {
    //     const dfMessenger = document.querySelector('df-messenger');
    //     dfMessenger.addEventListener('df-messenger-ready', () => {
    //       console.log('test df');
    //       // Inject additional parameters to the Dialogflow Messenger
    //       dfMessenger.setAttribute('userId', 'value1');
    //       dfMessenger.setAttribute('userCity', 'value2');
    //     });
    // }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
        });
      }
    });

    document.getElementById('preloader').classList.add('hide');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // this.sidenav.close();
        this.settings.mainToolbarFixed = false;
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
        });
      }
    });
  }

  private registerMaterialIcons(): void {
    this._registry.addSvgIcon(
      'globe',
      this._sanitize.bypassSecurityTrustResourceUrl('/assets/icons/globe.svg')
    );
    this._registry.addSvgIcon(
      'map-pin',
      this._sanitize.bypassSecurityTrustResourceUrl('/assets/icons/map-pin.svg')
    );

    this._registry.addSvgIcon(
      'guests',
      this._sanitize.bypassSecurityTrustResourceUrl('/assets/icons/guests.svg')
    );
    this._registry.addSvgIcon(
      'plane-in',
      this._sanitize.bypassSecurityTrustResourceUrl(
        '/assets/icons/plane-in.svg'
      )
    );
    this._registry.addSvgIcon(
      'plane-out',
      this._sanitize.bypassSecurityTrustResourceUrl(
        '/assets/icons/plane-out.svg'
      )
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
