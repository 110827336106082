import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { StorageNameElement } from '@shared/_models/storage.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'token';
  private readonly REFRESH_TOKEN = 'refreshToken';
  private loggedUser: string;
  private user: User;

  constructor(
    public http: HttpClient,
    private router: Router
  ) {}

  // Functions For User Changes Detection
  private userChanged = new BehaviorSubject(false);
  userChangedObs = this.userChanged.asObservable();

  // Trigger
  triggerUserChanges(message: boolean) {
    // Change the subject value
    this.userChanged.next(message);
  }

  // Functions 2FA auth
  private twoFA = new BehaviorSubject(false);
  twoFAObs = this.twoFA.asObservable();

  // Trigger
  trigger2FA(message: boolean) {
    // Change the subject value
    this.twoFA.next(message);
  }

  login(data): Observable<boolean> {
    return this.http.post('/bestinform/login', data).pipe(
      tap((tokens: any) => this.doLoginUser(data.email, tokens)),
      map((res) => {
        console.log('login service', res);
        return res;
      })
    );
  }

  tokens(data): Observable<boolean> {
    console.log('Tokens sessionid data', data);
    return this.http.get('/bestinform/tokens/' + data).pipe(
      tap((tokens: any) => { if(tokens.success) { this.storeTokens(tokens); } }),
      map((res) => {
        console.log('Tokens returned', res);
        return res;
      })
    );
  };

  login2FA(authenticationCode) {
    return this.http
      .post('/bestinform/login2FA?authenticationCode=' + authenticationCode, {})
      .pipe(
        tap((tokens: any) => this.doLoginUser(authenticationCode, tokens)),
        map((res) => {
          console.log('2fa login service', res);
          return res;
        }),
        catchError((error) => {
          console.log('2fa error login service', error);
          return of(false);
        })
      );
  }

  sendAuthenticationCode(dataObj: object) {
    return this.http.post('/bestinform/sendAuthenticationCode', dataObj);
  }

  sendUserInvalidSessionEmail(email: string) {
    return this.http.get(
      '/bestinform/sendUserInvalidSessionEmail?email=' + email
    );
  }

  private doLoginUser(username?: string, tokens?) {
    console.log('do login service', tokens);
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private storeTokens(tokens) {
    console.log('store tokens', tokens);
    localStorage.setItem(this.JWT_TOKEN, tokens.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  logout() {
    this.loggedUser = null;
    this.http.post('/bestinform/logoutUser', {}).subscribe((resp: any) => {
      console.log('m-am delogat', resp);
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem(StorageNameElement.CurrentLanguage, 'ro');
      this.router.navigate(['/']);
    });
  }

  refreshToken() {
    return this.http
      .post(
        '/bestinform/refreshToken?refreshToken=' + this.getRefreshToken(),
        {}
      )
      .pipe(
        tap((tokens) => {
          this.storeTokens(tokens);
        }),
        catchError((error) => {
          console.log('error refresh token');
          this.logout();
          return of(false);
        })
      );
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getJWTToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  isLoggedIn() {
    return !!this.getJWTToken();
  }

  register(user: object) {
    return this.http.post('/bestinform/registerUser', user);
  }

  sendRegistrationEmail(id: string) {
    return this.http.get('/bestinform/sendRegistrationEmail?userId=' + id);
  }

  sendResetUserPassword(email: string) {
    return this.http.get('/bestinform/sendResetUserPassword?email=' + email);
  }

  resetPassword(password: object) {
    return this.http.post('/bestinform/resetNewPassword', password);
  }

  getCurrentUser() {
    return this.http.get<User>('/bestinform/getCurrentUser');
  }

  loginWithFacebook(role?: string, email?: string, token?: string) {
    return this.http
      .post('/bestinform/loginWithFacebook?role=client', { accessToken: token })
      .pipe(
        tap((tokens: any) => this.doLoginUser(email, tokens)),
        map((res) => {
          console.log('login service social', res);
          return res;
        })
      );
  }
}
