import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-regulation-card',
  standalone: true,
  imports: [NgOptimizedImage],
  template: `
    <div class="regulation-card">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <img
          src="assets/images/regulations/european-union.png"
          alt="regulator logo"
          height="70" />
        <img
          src="assets/images/regulations/instrumente.png"
          alt="regulator logo"
          height="70" />
        <img
          src="assets/images/regulations/romania-gov.png"
          alt="regulator logo"
          height="70" />
      </div>
      <div class="d-flex justify-content-center">
        <a
          href="https://www.oipsi.gov.ro"
          target="_blank"
          class="regulation-link"
          >www.oipsi.gov.ro</a
        >
      </div>
    </div>
  `,
  styles: [
    `
      .regulation-card {
        background: #fff;
        border-radius: 14px;
        border: 1px solid #d5d6e2;
        padding: 8px 16px 7px;
        width: 300px;
      
      }

      .regulation-link {
        color: #8588ab;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        margin-top: 1px;
        text-decoration-line: underline;
      }
    `,
  ],
})
export class RegulationCardComponent {}
